var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.candidates)?_c('div',{staticClass:"hiring-report"},[_c('h1',[_vm._v("Сводка по кандидатам")]),_c('div',{staticClass:"options"},[_c('form',{staticClass:"options__item calendar"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dates.minDate),expression:"dates.minDate"}],attrs:{"type":"date","name":"start","id":"start"},domProps:{"value":(_vm.dates.minDate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dates, "minDate", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dates.maxDate),expression:"dates.maxDate"}],attrs:{"type":"date","name":"end","id":"end"},domProps:{"value":(_vm.dates.maxDate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dates, "maxDate", $event.target.value)}}}),_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.defaultDates.apply(null, arguments)}}},[_vm._v("Сбросить")])])]),_c('table',[_c('thead',[_c('tr',_vm._l((_vm.header),function(head,h){return _c('th',{key:h},[_vm._v(" "+_vm._s(head)+" ")])}),0)]),_c('tbody',_vm._l((_vm.datesFilter()),function(candidate,c){return _c('tr',{key:c},[_c('td',[_vm._v(_vm._s(c + 1))]),_c('td',[_vm._v(_vm._s(_vm.datetimeConverter(candidate.datetime)))]),_c('td',[_vm._v(_vm._s(candidate.title))]),_c('td',[_vm._v(_vm._s(candidate.supervisor))]),_c('td',[_vm._v(_vm._s(candidate.name))]),_c('td',{staticClass:"status",style:({
            background: _vm.candidateStatusList.filter(
              function (s) { return s.status === candidate.status; }
            )[0].bgColor,
          }),on:{"click":function($event){$event.preventDefault();_vm.openStatusPopup(
              candidate.candidateID,
              _vm.candidateStatusList.filter(
                function (s) { return s.status === candidate.status; }
              )[0].bgColor
            )}}},[_vm._v(" "+_vm._s(candidate.status)+" ")]),_c('td',{on:{"click":function($event){$event.preventDefault();return _vm.changeComment(candidate.candidateID)}}},[_vm._v(" "+_vm._s(candidate.comment)+" ")])])}),0)]),_c('CommentPopUp'),_c('StatusesPopUp')],1):_c('div',[_vm._v("Идёт загрузка")])}
var staticRenderFns = []

export { render, staticRenderFns }